<template>
<form novalidate class="md-layout" @submit.prevent="onAdd">

     <md-card id="formcard">
        <md-card-header>
          <md-card-header-text >
         
               <md-autocomplete name="name" id="name" v-model="name" :md-options="autocompleteOptions">
                  <label for="name">Name</label>
               </md-autocomplete>
           
            <div class="md-subhead">{{ amount }} kpl</div>
          </md-card-header-text>

          <md-menu md-size="big" md-direction="bottom-end">
            <md-button class="md-icon-button" md-menu-trigger>
              <md-icon>more_vert</md-icon>
            </md-button>

            <md-menu-content>
              <md-menu-item>
                <span>Remove</span>
              </md-menu-item>

            </md-menu-content>
          </md-menu>
        </md-card-header>

        <md-card-actions md-alignment="space-between">
          <div>
            <md-button class="md-icon-button" @click="amount += 1"
              ><md-icon>add_circle</md-icon></md-button
            >
            <md-button class="md-icon-button" @click="amount = Math.max(amount - 1, 0)"
              ><md-icon>remove_circle</md-icon></md-button
            >
          </div>
                      <md-button class="md-primary" type="submit"
              >Add to list</md-button
            >
        </md-card-actions>
      </md-card>
</form>
</template>

<script>

export default {
    name: "AddForm",
    data() {
      return {
        name: "",
        amount: 1
      }
    },

    props : {
      favorites: Array
    },

    computed : {
      autocompleteOptions(){
        return this.favorites.filter(f => f.toUpperCase().startsWith(this.name.toUpperCase()))
      }
    },
    methods: {
     onAdd(){
       if(this.name == "") return
       this.$emit('add-item', {name: this.name, amount: this.amount, checked: false})
       this.name = ""
       this.amount = 1
     }
    }
}


</script>

<style scoped>
#formcard{
  width: 100%;
}
</style>
<template>
  <div id="app" >
    <template v-if="hasFetchedOnce">
    <h1>Shopping List</h1>
    <li id="item-list">
      <Item
        v-for="item in items"
        :key="item.name"
        :item="item"
        :favorite="favorites.includes(item.name)"
        @update-item="updateItem"
        @remove-item="removeItem"
        @update-favorite="updateFavorite"
        class="listcard"
      />

      <AddForm @add-item="addItem" class="listcard" id="addform" :favorites="favorites"/>
    </li>
    <md-button class="md-raised md-primary" @click="removeAll"
      >Remove All</md-button
    >
    <md-button class="md-raised md-primary" @click="removeChecked"
      >Remove Checked</md-button
    >
    <md-button class="md-raised md-primary" @click="clearChecked"
      >Clear Checked</md-button
    >
    </template>

    <md-snackbar
      md-position="center"
      :md-duration="4000"
      :md-active.sync="showError"
    >
      {{ errorMessage }}
    </md-snackbar>
  </div>
</template>

<script>
import Item from "./components/Item.vue";
import AddForm from "./components/AddForm.vue";
import axios from "axios";

const baseUrl = "https://shop.tefy.net/api";

export default {
  name: "App",
  components: {
    Item,
    AddForm,
  },
  data() {
    return {
      items: [],
      favorites: [],
      timer: 0,
      hasFetchedOnce: false,
      errorMessage: "",
    };
  },

  computed: {
    showError: {
      get() {
        return this.errorMessage != "";
      },
      set(val) {
        if (!val) this.errorMessage = "";
      },
    },
  },

  methods: {
    updateItem(item) {
      axios
        .put(baseUrl + "/items/" + item.name, item)
        .then((r) => this.intervalFetch())
        .catch(this.handleError);
    },
    addItem(item) {
      axios
        .post(baseUrl + "/items", item)
        .then((r) => this.intervalFetch())
        .catch(this.handleError);
    },
    removeItem(item) {
      axios
        .delete(baseUrl + "/items/" + item.name)
        .then((r) => this.intervalFetch())
        .catch(this.handleError);
    },

    removeAll() {
      axios
        .all(this.items.map((i) => axios.delete(baseUrl + "/items/" + i.name)))
        .then((r) => this.intervalFetch())
        .catch(this.handleError);
    },

    removeChecked() {
      axios
        .all(
          this.items
            .filter((i) => i.checked)
            .map((i) => axios.delete(baseUrl + "/items/" + i.name))
        )
        .then((r) => this.intervalFetch())
        .catch(this.handleError);
    },

    clearChecked() {
      axios
        .all(
          this.items
            .filter((i) => i.checked)
            .map((i) =>
              axios.put(baseUrl + "/items/" + i.name, { ...i, checked: false })
            )
        )
        .then((r) => this.intervalFetch())
        .catch(this.handleError);
    },

    updateFavorite(name, status) {
      if(status){
        axios
        .post(baseUrl + "/favorites", {name: name})
        .then((r) => this.intervalFetch())
        .catch(this.handleError);
      } else {
        axios
        .delete(baseUrl + "/favorites/" + name)
        .then((r) => this.intervalFetch())
        .catch(this.handleError);
      }
    },

    handleError(error) {
      if (error.response) {
        this.errorMessage = error.response.data;
      } else if (error.request) {
        this.errorMessage = "Server did not respond!";
      } else {
        this.errorMessage = "Could not send the request";
      }
    },

    intervalFetch() {
      if (this.timer) clearInterval(this.timer);
      this.fetchData().then(() => {
        this.timer = setInterval(this.fetchData, 1000);
      });
    },

    fetchData() {
      return axios.all([
        axios.get(baseUrl + "/items"),
        axios.get(baseUrl + "/favorites")
      ])
      .then(axios.spread((itemRes, favRes) => {
         this.hasFetchedOnce = true;
         this.items = itemRes.data
         this.favorites = favRes.data.sort()
      }))
      .catch(e => {
        this.errorMessage = "Cannot fetch data from the server!"
      })
    },
  },

  mounted() {
    this.intervalFetch();
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style>
#app {
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#item-list {
  width: 320px;
  max-width: 100%;
  margin: auto;
  list-style: none;
}

.listcard {
  margin-bottom: 15px;
}

.md-speed-dial {
  z-index: 999;
}
</style>

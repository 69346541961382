<template >
     <md-card id="itemcard">
        <md-card-header>
          <md-card-header-text>
            <div class="md-title">{{ item.name }}</div>
            <div class="md-subhead">{{ item.amount }} kpl</div>
          </md-card-header-text>

          <md-menu md-size="big" md-direction="bottom-end">
            <md-button class="md-icon-button" md-menu-trigger>
              <md-icon>more_vert</md-icon>
            </md-button>

            <md-menu-content>
              <md-menu-item @click="removeItem">
                <span>Remove</span>
                <md-icon>clear</md-icon>
              </md-menu-item>

            </md-menu-content>
          </md-menu>
        </md-card-header>

        <md-card-actions md-alignment="space-between">
          <div>
            <md-button class="md-icon-button" @click="$emit('update-item', {...item, amount: item.amount + 1})"
              ><md-icon>add_circle</md-icon></md-button
            >
            <md-button class="md-icon-button" @click="$emit('update-item', {...item, amount: Math.max(item.amount - 1, 0)})"
              ><md-icon>remove_circle</md-icon></md-button
            >
            <md-button class="md-icon-button" @click="$emit('update-favorite', item.name, !favorite)"
              ><md-icon>{{favorite ? "favorite" : "favorite_border"}}</md-icon></md-button
            >
          </div>
          <md-checkbox :value="!item.checked" @change="$emit('update-item', {...item, checked: !item.checked})"></md-checkbox>
        </md-card-actions>
      </md-card>
</template>

<script>
export default {
    name: "Item",
    props : {
        item: Object,
        favorite: Boolean
    },
    methods: {
      removeItem(){
        setTimeout(() => this.$emit('remove-item', this.item), 300)
      }
    }
    

    
}
</script>